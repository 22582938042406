@import "../../../variables.scss";

.cr-intro-bar{
    min-height: 100px;
    background-color: $color-main;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cr-intro-bar h1{
    color: $white;
}
