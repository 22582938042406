@import "./../../../variables.scss";

.btn-small,
.btn-medium,
.btn-large,
.btn-full,
.btn-medium-full {
    border-radius: 4px;
    padding: 4px 14px;
    text-decoration: none;
    transition: ease-in 0.4s;
    font-size: $fontSmall;
    font-family: $fontRoman;
    cursor: pointer;
}

.btn-medium {
    font-size: $fontText;
    padding: 6px 18px;
}

.btn-large {
    font-size: $fontH3;
    padding: 10px 24px;
}

.btn-full {
    display: flex;
    width: 100%;
    justify-content: center;
    border-radius: 4px;
    padding: 10px;
    text-decoration: none;
    transition: ease-in 0.4s;
    font-size: $fontH3;
    font-family: $fontBold;
}
.btn-medium-full{
    display: flex;
    font-size: $fontText;
    width: 100%;
    padding: 6px 12px;
}

.btn-orange {
    color: $white;
    background-color: $color-two;
    border: 1px solid $color-two;
}

.btn-orange:hover {
    color: $color-two;
    background-color: $white;
    border: 1px solid $color-two;
}

.btn-blue {
    background-color: $color-main;
    border: 1px solid $color-main;
    color: $white;
}

.btn-blue:hover {
    background-color: $white;
    color: $color-main;
    border: 1px solid $color-main;
}

.btn-line-white {
    border: 1px solid $white;
    color: $white;
    background: transparent;
    transition: 0.3s ease-in-out;
}

.btn-line-white:hover {
    background-color: $white;
    color: $color-main;
    transition: 0.3s ease-in-out;
    border: 1px solid $white;
}

.btn-line-blue {
    border: 1px solid $color-main;
    color: $color-main;
    background: transparent;
    transition: 0.3s ease-in-out;
}

.btn-line-blue:hover {
    background-color: $color-main;
    color: $white;
    transition: 0.3s ease-in-out;
    border: 1px solid $color-main;
}



@media (max-width: 767px) {
    .btn-small,
    .btn-medium,
    .btn-large,
    .btn-full {
        padding: 2px 10px;
    }

    .btn-medium {
        font-size: $fontText;
        padding: 4px 10px;
    }

    .btn-large {
        font-size: $fontH3;
        padding: 6px 18px;
    }

    .btn-full {
        display: flex;
        width: 100%;
        justify-content: center;
        border-radius: 4px;
        padding: 8px;
        text-decoration: none;
        transition: ease-in 0.4s;
        font-size: $fontH3;
        font-family: $fontBold;
    }
}
