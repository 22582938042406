@font-face {
    font-family: "Neue Haas Grotesk Display Pro 45 Light";
    font-style: normal;
    font-weight: normal;
    src: url("assets/fonts/NeueHaasDisplayLight.woff") format("woff"),
        url("assets/fonts/NeueHaasDisplayLight.woff2") format("woff2");
}

@font-face {
    font-family: "Neue Haas Grotesk Display Pro 55 Roman";
    font-style: normal;
    font-weight: normal;
    src: url("assets/fonts/NeueHaasDisplayRoman.woff") format("woff"),
        url("assets/fonts/NeueHaasDisplayRoman.woff2") format("woff2");
}

@font-face {
    font-family: "Neue Haas Grotesk Display Pro 75 Bold";
    font-style: normal;
    font-weight: normal;
    src: url("assets/fonts/NeueHaasDisplayBold.woff") format("woff"),
        url("assets/fonts/NeueHaasDisplayBold.woff2") format("woff2");
}

