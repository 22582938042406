$fontLight:"Neue Haas Grotesk Display Pro 45 Light";
$fontRoman:"Neue Haas Grotesk Display Pro 55 Roman";
$fontBold:"Neue Haas Grotesk Display Pro 75 Bold";
$fontXL: 80px;
$fontL: 50px;
$fontH1: 36px;
$fontH2: 28px;
$fontH3: 24px;
$fontText: 18px;
$fontSmall: 14px;
$fontXS:9px;


$color-main: #0C88C2;
$color-main10: #EBF9FF;
$color-main20: #91D8F7;
$color-main40: #2b97c5;
$color-main90: #0A5375;
$color-two: #F5874F;
$color-two10: #fff3ee;
$color-two50: #e6530a;

$black: #000;
$black90:#333333;
$black80:#5D636A;
$black70:#767D84;
$black60:#BABDC1;
$black20:#F5F6F6;
$white: #fff;


$muito-facil: #91D8F7;
$facil: #0C88C2;
$razoavel: #67BF25;
$razoavel120: #01a435;
$razoavel10: #ecffdd;
$dificil:#F5874F;
$muito-dificil: #E10808;


hr{

    border:none;
    height: 1px;
    background-color: $color-main20;
    opacity: 1;
}
