#pencil {
  height: 100px;
  width: 200px;
  position: absolute;
  top: 0;
  bottom: 200px;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(120deg);
  animation: move 6s linear infinite;
}

@keyframes move {
  0% {
    transform: rotate(120deg);
    left: 0;
    bottom: 150;
  }

  5% {
    transform: rotate(120deg);
    left: 0;
    bottom: 150;
  }

  20% {
    transform: rotate(300deg);
    left: 200px;
    bottom: 210px;
  }

  30% {
    transform: rotate(300deg);
    left: 300px;
    bottom: 210px;
  }

  50% {
    transform: rotate(300deg);
    left: 480px;
    bottom: 210px;
  }

  70% {
    transform: rotate(480deg);
    left: 260px;
    bottom: 150px;
  }

  100% {
    transform: rotate(480deg);
    left: 0;
    bottom: 150px;
  }
}

@keyframes draw {
  0% {
    width: 0;
  }

  5% {
    width: 0;
  }

  20% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  50% {
    width: 300px;
    bottom: 250;
  }

  70% {
    width: 300px;
    bottom: 250;
  }

  100% {
    width: 0;
  }
}

.pbody {
  height: 35px;
  width: 130px;
  background-color: #0c88c2;
}

#eraser {
  position: absolute;
  height: 35px;
  width: 30px;
  left: 130px;
  top: 0;
  background-color: #f48245;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pbody::before {
  content: "";
  position: absolute;
  left: -80px;
  top: 0;
  border-style: solid;
  border-color: transparent #eeb99d transparent transparent;
  border-width: 17px 40px 18px;
}

.pbody::after {
  content: "";
  position: absolute;
  top: 0;
  left: -80px;
  border-style: solid;
  border-color: transparent #f48245 transparent transparent;
  border-width: 17px 40px 18px;
  transform: scale(0.3);
}


.line {
  width: 0;
  height: 4px;
  background: #f48245;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: draw 6s linear infinite;
}

#text {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  font-size: 20px;
}

.loading-container {
  top: 0;
  left: 0;
  z-index: 999999999;
  font-family: sans-serif;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: rgba(0, 27, 47, 0.95);
  color: #0c88c2;
  font-size: 1.5rem;
}

.loading-text {
  padding-top: 45px;
  color: #f48245;
  font-size: 1.5rem;
}
