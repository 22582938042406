@import "./styles/fonts.scss";
@import "./styles/normalize.scss";
@import "./variables.scss";


html {
  height: 100%;
  box-sizing: border-box;

}

*,
*::before,
*::after {
  box-sizing: inherit;
  letter-spacing: 0.4px;
  outline: none;
}

body {
  font-family: $fontRoman, sans-serif;
  font-size: $fontText;
  color: $black80;

  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


ul, li {
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4 {
  margin: 0;
  color: $color-main;
}

h1 {
  font-size: $fontH1;
  font-family: $fontBold;
}

h2 {
  font-size: $fontH2;
  font-family: $fontBold;
}

h3 {
  font-size: $fontH3;
  font-family: $fontBold;
}


.cr-text-center {
  text-align: center;
  justify-content: center;
}

input:focus {
  box-sizing: border-box;
  border: 1px solid $color-main;
  outline: $color-main;
}

code {
  font-family: $fontLight, "Menlo", "Monaco", "Consolas", "Courier New", monospace;
}

a {
  text-decoration: none;
}

.hidden {
  display: none;
}


h1.head-line {
  font-size: $fontH1;
  font-family: $fontBold;
  color: $color-main;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-main;
}


.cr-mg-left4 {
  margin-left: 4px;
}

.cr-mg-left20 {
  margin-left: 20px;
}

/*Titulos*/


.cr-blue {
  color: $color-main;
}

.cr-white {
  color: $white;
}

.cr-h0 {
  font-size: $fontXL;
  font-family: $fontBold;
}

.cr-h1 {
  font-size: $fontH1;
  font-family: $fontBold;
}

.cr-h2 {
  font-size: $fontH2;
  font-family: $fontBold;
}

.cr-h3 {
  font-size: $fontH3;
  font-family: $fontBold;
}


@media(max-width: 992px) {
  .cr-h0 {
    font-size: $fontH1;
    font-family: $fontBold;
  }

  .cr-h1 {
    font-size: $fontH2;
    font-family: $fontBold;
  }

  .cr-h2 {
    font-size: $fontH3;
    font-family: $fontBold;
  }

  .cr-h3 {
    font-size: $fontText;
    font-family: $fontBold;
  }
}


.nav-link {
  color: $black60;
}

.nav-link:hover {
  color: $color-main;
}

.table {
  margin-bottom: 0;
  font-size: $fontSmall;
}
